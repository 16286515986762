import moment from "moment";

export const convertMillisecondsToTime = (
  milliseconds: number,
  date = new Date()
) => {
  // Create a moment object for the given date
  const momentDate = moment(date).startOf("day");

  // Add the milliseconds to the start of the day
  const timeMoment = momentDate.add(milliseconds, "milliseconds");

  // Format as needed
  return timeMoment;
};

export const convertDateToMilliseconds = (date: any) => {
  const timeStr = moment(date).format("HH:mm");
  const hour = moment(timeStr, "HH:mm");
  return hour.hour() * 3600000 + hour.minute() * 60000;
};

export const formatDate = (date: number) =>
  new Date(date).toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

export const convertDateToTimeStamp = (date: any) => {
  const formatDate: any = moment(date).format('MM-DD-YYYY');
  const timestamp = moment(formatDate).valueOf();
  console.log("formatDate-----",formatDate)
 return timestamp
}


export const formatDateTime = (date: number) =>
  new Date(date).toLocaleString("default", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });