import React, { useState, useEffect } from "react";
import Header from "../components/Header/index";
import Sidebar from "../components/Sidebar/index";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "src/hooks/useIdleTimer";
import { LoginExpireModal } from "src/components/LoginExpireModal/LoginExpiremodal";
import { useSelector } from "react-redux";
import ShowTokenRefreshModal from "src/components/ShowTokenRefreshModal/ShowTokenRefreshModal";
import DeleteRestaurant from "src/components/DeleteRestaurant/DeleteRestaurant";
import { useAuth } from "src/contexts/auth-provider";
import DeleteCategory from "src/components/DeleteRestaurant/DeleteCategory";
import DeleteCategoryItem from "src/components/DeleteRestaurant/DeleteCategoryItem";
import DeleteTable from "src/components/DeleteRestaurant/DeleteTable";
import DeleteCombo from "src/components/DeleteRestaurant/DeleteCombo";

export const DefaultLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth <= 768 ? false : true);
  const [pageTitle, setPageTitle]: any = useState('Dashboard');
  const { pathname } = useLocation();
  const { user } = useAuth();
  const auth = useSelector((state: any) => state.auth);
  const { hasStoreId } = useSelector(
    (state: any) => state.restaurant
  );

  const { hasCategoryId } = useSelector(
    (state: any) => state.category
  );

  const { hasCategoryItemId } = useSelector(
    (state: any) => state.categoryItem
  );

  const { hasTableId } = useSelector(
    (state: any) => state.restaurantTable
  );
  const { hasComboId } = useSelector(
    (state: any) => state.combo
  );

  useIdleTimer();

  useEffect(() => {
    const handleResize = () => window.innerWidth <= 768 && setSidebarOpen(false);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hideLayout = [
    "/create-restaurant",
    "/upload-image",
    "/plans",
    "/stripe-connection",
    "/success",
    "/cancel",
    "/loginexpair",
  ].includes(pathname);
  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark h-full">
      {auth.showExpiryLoginModal && <LoginExpireModal />}
      {auth.isShowTokenRefreshModal && <ShowTokenRefreshModal />}
      
      {hasStoreId && (
        <DeleteRestaurant
          restaurantId={hasStoreId}
        />
      )}

      {hasCategoryId && (
        <DeleteCategory
        categoryId={hasCategoryId}
        />
      )}

      {hasCategoryItemId && (
        <DeleteCategoryItem
          itemId={hasCategoryItemId}
        />
      )}

      {hasTableId && (
        <DeleteTable
          tableId={hasTableId}
        />
      )}

      {hasComboId && (
        <DeleteCombo
          comboId={hasComboId}
        />
      )}


      {/* Page Wrapper Start */}
      <div className="flex h-full overflow-hidden">
        {/* Sidebar Start */}
        {!hideLayout && (
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} setPageTitle={setPageTitle} />
        )}
        {/* Sidebar End */}

        {/*  Content Area Start  */}
        <div className="relative flex flex-1 bg-[#FAFAFA] flex-col overflow-y-auto">
          {/* Header Start */}

          {!hideLayout && (
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} user={user} pageTitle={pageTitle} />
          )}
          {/* Header End */}

          {/* Main Content Start  */}
          <main>
            <div
              className={
                !hideLayout
                  ? "mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10"
                  : ""
              }
            >
              {/* {children} */}
              <Outlet />
            </div>
          </main>
          {/* Main Content  */}
        </div>
        {/* Content Area  */}
      </div>
      {/* Page Wrapper End */}
    </div>
  );
};
