import { useEffect, useState } from "react";
import SortIcon from "../../assests/images/dashboard/swap.png";
import { sort_tables } from "src/store/features/restuarant-table-slice";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { GET_PROMOTIONS } from "src/constants/queries";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { BtnLoader } from "src/Loader";
import { formatDate } from "src/utils/date";
import SortbyDropdown from "src/pages/Menu/SortbyDropdown";
import ArrowUpIcon from "src/icons/ArrowUPIcon";
import ArrowDownIcon from "src/icons/ArrowDownIcon";

const CustomerPromotions = () => {
  const [sortConfig, setSortConfig] = useState({ key: "", order: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpend, setDropdownOpen] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [filteredPromotions, setFilteredPromotions] = useState([]);
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const emailDropdown = () => {
    setDropdownOpen(!isDropdownOpend);
  };

  useEffect(() => {
    const getPromotions = async () => {
      setLoading(true);
      try {
        const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
          query: GET_PROMOTIONS,
          variables: {
            storeCorrelationId: hasRestaurant.correlationId,
          },
        });
        setPromotions(data.data.promotionsByStoreCorrelationId);
        setFilteredPromotions(data.data.promotionsByStoreCorrelationId);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    getPromotions();
  }, []);

  const searchPromotions = (search: string) => {
    setFilteredPromotions(
      promotions.filter(
        (promotion: any) =>
          promotion.stripePromotionCode
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          promotion.eatsbitsUserEmail
            ?.toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  };

  const sortData = (key: any, order = "asc") => {
    const sortedData = [...filteredPromotions].sort((a: any, b: any) => {
      let comparison = 0;
      setSortConfig({ key, order });
      if (typeof a[key] === "string" && typeof b[key] === "string") {
        comparison = a[key].localeCompare(b[key]);
      } else {
        comparison = a[key] - b[key];
      }
      return order === "desc" ? -comparison : comparison;
    });
    setFilteredPromotions(sortedData);
  };

  console.log("sortConfig --> ", sortConfig);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px] mt-6">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg"
                placeholder="Search Promotion..."
                onChange={(e) => searchPromotions(e.target.value)}
              />
            </div>
          </form>
        </div>
        {/* <div className="relative text-left flex items-center">
          <div className="flex justify-center my-4">
            <button
              className={`flex text-sm items-center justify-evenly duration-500 w-[118px] h-[38px] rounded-xl border-solid border border-[#FD7657] 
          ${
            isOpen
              ? "bg-[#FD76571A] text-[#FD7657]"
              : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
          }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown sortBy={sortPromotions} isDispatched={false} />}
        </div> */}
      </div>

      {loading ? (
        <div className="absolute top-[50%] left-[50%]">
          <BtnLoader />
        </div>
      ) : filteredPromotions?.length === 0 ? (
        <div className="absolute top-[50%] left-[25%]">
          <p>
            No promotions available. Click the add button to create some
            Promotions!
          </p>
        </div>
      ) : (
        <div className="w-full pb-3 mt-6 flex justify-center rounded-xl bg-white">
          <table className="w-full text-left">
            <thead>
              <tr className="p-20 text-[#9B9B9B] text-xs border-b border-borderGray">
                <th className="px-2 py-5 pl-5">
                  <div className="flex items-center">
                    USER EMAILS
                    <span className="ml-2 cursor-pointer">
                      <div onClick={() => sortData("eatsbitsUserEmail", "asc")}>
                        <ArrowUpIcon
                          color={
                            sortConfig.key === "eatsbitsUserEmail" &&
                            sortConfig.order === "asc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                      <div
                        className="mt-1"
                        onClick={() => sortData("eatsbitsUserEmail", "desc")}
                      >
                        <ArrowDownIcon
                          color={
                            sortConfig.key === "eatsbitsUserEmail" &&
                            sortConfig.order === "desc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                    </span>
                  </div>
                </th>
                <th className="px-2 py-5">
                  <div className="flex items-center">
                    EXPIRY
                    <span className="ml-2 cursor-pointer">
                      <div onClick={() => sortData("expiresAt", "asc")}>
                        <ArrowUpIcon
                          color={
                            sortConfig.key === "expiresAt" &&
                            sortConfig.order === "asc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                      <div
                        className="mt-1"
                        onClick={() => sortData("expiresAt", "desc")}
                      >
                        <ArrowDownIcon
                          color={
                            sortConfig.key === "expiresAt" &&
                            sortConfig.order === "desc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                    </span>
                  </div>
                </th>
                <th className="px-2 py-2">
                  <div className="flex items-center">
                    PERCENT OFF
                    <span className="ml-2 cursor-pointer">
                      <div onClick={() => sortData("percentOff", "asc")}>
                        <ArrowUpIcon
                          color={
                            sortConfig.key === "percentOff" &&
                            sortConfig.order === "asc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                      <div
                        className="mt-1"
                        onClick={() => sortData("percentOff", "desc")}
                      >
                        <ArrowDownIcon
                          color={
                            sortConfig.key === "percentOff" &&
                            sortConfig.order === "desc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                    </span>
                  </div>
                </th>
                <th className="px-2 py-5">
                  <div className="flex items-center">
                    PROMOTION CODE
                    <span className="ml-2 cursor-pointer">
                      <div
                        onClick={() => sortData("stripePromotionCode", "asc")}
                      >
                        <ArrowUpIcon
                          color={
                            sortConfig.key === "stripePromotionCode" &&
                            sortConfig.order === "asc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                      <div
                        className="mt-1"
                        onClick={() => sortData("stripePromotionCode", "desc")}
                      >
                        <ArrowDownIcon
                          color={
                            sortConfig.key === "stripePromotionCode" &&
                            sortConfig.order === "desc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                    </span>
                  </div>
                </th>
                <th className="px-2 py-5">
                  <div className="flex items-center">
                    MAX REDEMPTIONS
                    <span className="ml-2 cursor-pointer">
                      <div onClick={() => sortData("maxRedemptions", "asc")}>
                        <ArrowUpIcon
                          color={
                            sortConfig.key === "maxRedemptions" &&
                            sortConfig.order === "asc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                      <div
                        className="mt-1"
                        onClick={() => sortData("maxRedemptions", "desc")}
                      >
                        <ArrowDownIcon
                          color={
                            sortConfig.key === "maxRedemptions" &&
                            sortConfig.order === "desc"
                              ? "#FD7657"
                              : "#9B9B9B"
                          }
                        />
                      </div>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredPromotions?.map((promotion: any, index: any) => {
                const isOdd = index % 2 !== 0;
                return (
                  <tr
                    key={index}
                    className={`${
                      filteredPromotions?.length !== index + 1 && "border-b"
                    } p-20 text-[#252525] text-sm font-normal border-[#EBF5FF] ${
                      isOdd ? "bg-[#FCFCFC]" : ""
                    }`}
                  >
                    <td className="px-2 py-5 pl-5">
                      {promotion.eatsbitsUserEmail}
                    </td>
                    <td className="px-2 py-5">
                      {formatDate(promotion?.expiresAt)}
                    </td>
                    <td className="px-2 py-5">{promotion.percentOff}%</td>
                    <td className="px-2 py-5">
                      {promotion.stripePromotionCode}
                    </td>
                    <td className="px-2 py-5">
                      {promotion.maxRedemptions || "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomerPromotions;
