import { useState } from "react";
import SortIcon from "../../assests/images/dashboard/swap.png";
import SortbyDropdown from "../Menu/SortbyDropdown";
import { sort_tables } from "src/store/features/restuarant-table-slice";
import ArrowUpIcon from "src/icons/ArrowUPIcon";
import ArrowDownIcon from "src/icons/ArrowDownIcon";

const Ordermanagement = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const data = [
    {
      order: "DD773536",
      pickup: "56151",
      customer: "Bessie",
      combo: "Sizzle",
      item: "Sushi, Cola",
      price: "$17.84",
      paid: true,
      description: "Allergic to mushrooms...",
      status: "Error",
    },
    {
      order: "DD773536",
      pickup: "56151",
      customer: "Bessie",
      combo: "Sizzle",
      item: "Sushi, Cola",
      price: "$17.84",
      description: "Allergic to mushrooms...",
      status: "Error",
      paid: true,
    },
    {
      order: "DD773536",
      pickup: "56151",
      customer: "Bessie",
      combo: "Sizzle",
      item: "Sushi, Cola",
      price: "$17.84",
      description: "Allergic to mushrooms...",
      status: "Complete",
      paid: false,
    },
    {
      order: "DD773536",
      pickup: "56151",
      customer: "Bessie",
      combo: "Sizzle",
      item: "Sushi, Cola",
      price: "$17.84",
      description: "Allergic to mushrooms...",
      status: "Pending",
      paid: true,
    },
    {
      order: "DD773536",
      pickup: "56151",
      customer: "Bessie",
      combo: "Sizzle",
      item: "Sushi, Cola",
      price: "$17.84",
      description: "Allergic to mushrooms...",
      status: "Error",
      paid: false,
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg"
                placeholder="Search Order..."
              />
            </div>
          </form>
        </div>
        <div className="relative text-left flex items-center">
          <div className="m-5 ml-3"></div>
          <div className="flex justify-center my-4">
            <button
              className={`flex text-sm items-center justify-evenly duration-500 w-[118px] h-[38px] rounded-xl border-solid border border-[#FD7657] 
          ${
            isOpen
              ? "bg-[#FD76571A] text-[#FD7657]"
              : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
          }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown sortBy={sort_tables} />}
        </div>
      </div>

      <div className="w-full bg-white flex  justify-center rounded-xl mt-4">
        <table className="w-full text-left">
          <thead>
            <tr className="p-20 text-[#9B9B9B] text-xs border-b border-borderGray">
              <th className="px-2 py-5 pl-5">CREATED AT</th>
              <th className="px-2 py-5">IS GUEST</th>
              <th className="px-2 py-5">TYPE</th>
              <th className="px-2 py-5">TABLE NUMBER</th>
              <th className="px-2 py-5">PRICE</th>
              <th className="px-2 py-5">ITEM</th>
              <th className="px-2 py-5">CUSTOMER NODE</th>
              <th className="px-2 py-5">PAID</th>
              <th className="px-2 py-5">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {data.map((val: any, index) => {
              const isOdd = index % 2 !== 0;
              return (
                <tr
                  key={index}
                  className={`${
                    data.length !== index + 1 && "border-b"
                  } p-20 text-[#252525] text-sm font-normal border-[#EBF5FF] ${
                    isOdd ? "bg-[#FCFCFC]" : ""
                  }`}
                >
                  <td className="px-2 py-5 pl-5">{val.order}</td>
                  <td className="px-2 py-5">{val.pickup}</td>
                  <td className="px-2 py-5">{val.customer}</td>
                  <td className="px-2 py-5">{val.combo}</td>
                  <td className="px-2 py-5">{val.item}</td>
                  <td className="px-2 py-5">{val.price}</td>
                  <td className="px-2 py-5">{val.description}</td>
                  <td className="px-2 py-5">
                    {val.paid == true ? "Paid" : "Not paid"}
                  </td>
                  <td className="px-2 py-5">
                    {" "}
                    <span
                      className={`${
                        val.status.toLowerCase() == "error" && "bg-[#F144451A] text-[#F14445]"
                      } ${
                        val.status.toLowerCase() == "complete" &&
                        "bg-[#3ACC481A] text-[#3ACC48]"
                      } ${
                        val.status.toLowerCase() == "pending" &&
                        "bg-[#F0C0451A] text-[#EAB42A]"
                      } rounded-[8px] py-2 px-4`}
                    >
                      {val.status}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Ordermanagement;

// useEffect(() => {
//   const getCustomers = async () => {
//     setLoading(true);
//     try {
//       // const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
//       //   query: GET_PROMOTIONS,
//       //   variables: {
//       //     storeCorrelationId: hasRestaurant.correlationId,
//       //   },
//       // });
//       setCustomers(existingCustomers);
//       setFilteredCustomers(existingCustomers);
//       setLoading(false);
//     } catch (error) {
//       console.error(error);
//       setLoading(false);
//     }
//   };
//   getCustomers();
// }, []);
