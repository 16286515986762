import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { SIGNIN_STORE_USER } from "src/constants/mutations";
import * as Yup from "yup";
import eyeIconVisible from "../../assests/images/landing-page/eye-open.png";
import eyeIcon from "../../assests/images/landing-page/visibility_off.png"; // Fixed the image for visibility on
import Logo from "../../components/common/Logo";
import { useAuth } from "../../contexts/auth-provider";
import { BtnLoader } from "../../Loader/index"
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";

export const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { setToken, setUser, setRefreshtoken } = useAuth();
  const [inProgress, setInProgress] = useState(false);
  const { axBe } = useAxiosInterceptor();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async () => {
      const variables = {
        storeUserSignInInput: {
          ...formik.values,
        },
      };
      if (!GRAPHQL_ENDPOINT) {
        console.error("GraphQL endpoint is not defined.");
        return;
      }
      try {
        setInProgress(true);
        const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
          query: SIGNIN_STORE_USER,
          variables: variables,
        });
        const token = data?.data?.storeUserSignIn?.accessToken;
        const refreshToken = data?.data?.storeUserSignIn?.refreshToken;
        const user = data?.data?.storeUserSignIn;
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        setRefreshtoken(refreshToken);
        setToken(token);
        setUser(user);
        navigate("/dashboard");
        setInProgress(false);
      } catch (error) {
        setInProgress(false);
      }
    },
  });

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="flex justify-center flex-col items-center">
      <div className="mt-[5%]">
        <div 
        onClick={() => navigate("/")}
        className="flex cursor-pointer">
          <Logo />
          <h1 className="ml-2 text-3xl">EatsBits</h1>
        </div>
      </div>
      <div className="flex justify-center items-center w-[100%]">
        <div className="max-sm:w-[100%] h-[430px] w-[420px] rounded-[12px] mt-[13%] p-6 border border-lightGrey">
          <h1 className="text-center text-2xl mt-4 font-semibold">Login</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-6">
              <p className="text-sm text-[#9B9B9B]">Email</p>
              <div className="flex items-center">
                <input
                  className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                  type="text"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="text-orange text-xs mt-2 mx-1">
                  {formik.errors.email}
                </div>
              ) : null}

              <div className="flex justify-between mt-8">
                <p className="text-sm text-[#9B9B9B]">Password</p>
                <Link to="/forgot-password">
                  <p className="text-sm text-orange cursor-pointer">
                    Forget Password?
                  </p>
                </Link>
              </div>
              <div className="flex items-center relative">
                <input
                  className="text-sm text-[#9B9B9B] h-[42px] w-[100%] border rounded-lg border-[#F1F1F2] mt-2 px-2"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter your password"
                  id="password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <img
                  onClick={togglePassword}
                  className="absolute right-[10px] top-[20px] cursor-pointer"
                  src={passwordVisible ? eyeIconVisible : eyeIcon}
                  alt="Toggle visibility"
                />
              </div>

              {formik.touched.password && formik.errors.password ? (
                <div className="text-orange text-xs ml-1 mt-2 ">
                  {formik.errors.password}
                </div>
              ) : null}

              <div className="mt-4 flex">
                <input type="checkbox" id="rememberMe" name="rememberMe" />
                <label htmlFor="rememberMe" className="text-sm ml-2">
                  Remember me
                </label>
              </div>

              <div className="flex justify-center my-4">
                {inProgress ? (
                  <BtnLoader />
                ) : (
                  <button
                    type="submit"
                    disabled={!formik.isValid || !formik.dirty}
                    className={`w-[100%] hover:bg-white hover:text-orange duration-500 text-white h-[40px] rounded-xl bg-[#FD7657] ${
                      (!formik.isValid || !formik.dirty) &&
                      "opacity-[50%] pointer-events-none"
                    }`}
                  >
                    Login
                  </button>
                )}
              </div>
              <div className="flex justify-center">
                <p className="text-sm">
                  <Link to="/sign-up">
                    Don't have an account?{" "}
                    <span className="text-orange">Signup now</span>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
