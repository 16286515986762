import DashboardIcon from "../../icons/DashboardIcon";
import RestuarantIcon from "../../icons/RestuarantIcon";
import RestuarantSettingIcon from "../../icons/RestaurantSetting";
import EmployeeIcon from "../../icons/EmployeeIcon";
import MenuIcon from "../../icons/MenuIcon";
import ComboIcon from "../../icons/ComboICon";
import TableICon from "../../icons/TableIcon";

export const ownerSidebar = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
  },
  {
    url: "/my-restaurants",
    name: "My Restaurants",
    icon: RestuarantIcon,
  },
];

export const restaurantSidebar = [
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/dashboard`,
    name: "Dashboard",
    icon: DashboardIcon,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/restaurant-setting`,
    name: "General Settings",
    icon: RestuarantSettingIcon,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/employee`,
    name: "Employee",
    icon: EmployeeIcon,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/restaurant-table`,
    name: "Table",
    icon: TableICon,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/promotions`,
    name: "Promotions",
    icon: EmployeeIcon,
  },
  {
    url: (correlationId: string) =>
      `/my-restaurant/${correlationId}/OrderManagement`,
    name: "Recent Order",
    icon: EmployeeIcon,
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/menu`,
    name: "Menu",
    section: "Manage",
    isSubMenuOpen: false,
    icon: MenuIcon,
    children: [],
  },
  {
    url: (correlationId: string) => `/my-restaurant/${correlationId}/combo`,
    name: "Combo",
    icon: ComboIcon,
  },
];
