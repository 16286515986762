import { useEffect, useState } from "react";
import DownloadIcon from "../../assests/images/dashboard/download.png";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { RESTAURANT_SUBSCRIPTION_TAB_DATA } from "src/constants/queries";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import { FRONTEND_URL } from "src/constants/endpoints";
import SkeletonLoader from "./SkeletonLoader";
import SubcriptionLoader from "./SubcriptionLoader";
import UpcomingInvoiceSkeleton from "./UpcommingLoading";
import { formatDate } from "src/utils/date";

const SubcriptionSetting = () => {
  const [restaurantSubcription, setRestaurantSubcription] = useState<any>([]);
  const { axBe } = useAxiosInterceptor();
  const { hasRestaurant } = useAuth();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    const fetchCharacters = async () => {
      try {
        const response = await axBe.post(GRAPHQL_ENDPOINT, {
          query: RESTAURANT_SUBSCRIPTION_TAB_DATA,
          variables: {
            restaurantSubscriptionTabDataInput: {
              storeCorrelationId: hasRestaurant.correlationId,
              clientBaseUrl: FRONTEND_URL,
            },
          },
        });
        const { data } = response.data;
        // console.log("Full Response Data:", data);
        if (data?.restaurantSubscriptionTabData) {
          setRestaurantSubcription(data.restaurantSubscriptionTabData);
          // console.log("Data Check:", data.restaurantSubscriptionTabData);
          setloading(false);
        } else {
          console.error("Unexpected result structure:", data);
          setloading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setloading(false);
      }
    };
    fetchCharacters();
  }, []);

  const openInvoice = (url: string) => window.open(url, "_blank");

  return (
    <div className="mt-6">
      {/* Subcription */}
      {loading ? (
        <SubcriptionLoader />
      ) : (
        <div className=" bg-white rounded-3xl w-full p-4">
          <div className="w-[100%] flex justify-between items-center gap-x-5">
            <div className="flex w-[100%] h-[200px] relative justify-between  p-6 rounded-xl border border-[#F1F1F2] mb-4">
              <div className="w-full">
                <h3 className="text-lg font-semibold">Your Plan</h3>
                <h3 className="text-sm text-[#606360] mt-4">
                  {restaurantSubcription?.subscribedPlan?.productName.toLowerCase()}
                </h3>
                <p className="text-xl font-semibold mt-2">
                  ${restaurantSubcription?.subscribedPlan?.price}/
                  {restaurantSubcription?.subscribedPlan?.frequency.toLowerCase() === "yearly" ? "year" : "month"}
                </p>
                <p className="text-[#606360] text-xs mt-2">
                  {restaurantSubcription?.subscribedPlan?.productDescription}
                </p>
              </div>
            </div>

            <div className="flex w-[100%] h-[200px] justify-center  p-6 rounded-xl border border-[#F1F1F2] mb-4">
              <div className="w-full">
                <h3 className="text-lg  text-[#606360] mt-2">
                  Visit your stripe customer portal to update payment method and plan
                </h3>
                <button
                  className="w-full mt-8 px-4 py-2 bg-[white] text-orange border border-[#F1F1F2] rounded-lg"
                  onClick={() =>
                    openInvoice(restaurantSubcription?.customerPortalUrl)
                  }
                >
                  Stripe customer portal
                </button>
              </div>
            </div>

            {/* Upcoming Invoice */}
          </div>
        </div>
      )}

      {/* UpComming Incoices */}
      {loading ? (
        <UpcomingInvoiceSkeleton />
      ) : (
        <div className="bg-white rounded-3xl px-6 py-4 mt-8">
          <section className="">
            <div className="flex items-center gap-x-4">
              <h3 className="text-lg font-medium ">Upcoming Invoice</h3>
              <p className="text-[#606360] text-xs">
                {formatDate(
                  restaurantSubcription?.upcomingInvoice?.nextPaymentAttempt *
                    1000
                )}
              </p>
            </div>

            <div className="flex justify-between bg-gray-100 mt-6 rounded-lg h-[40px] border-b border-[#EBF5FF]">
              <p className="text-sm">
                {restaurantSubcription?.upcomingInvoice?.description}
              </p>
              <p className="text-[#606360] text-sm">
                ${restaurantSubcription?.upcomingInvoice?.totalExcludingTax}
              </p>
            </div>
            <div className="flex justify-between bg-gray-100 mt-4 rounded-lg">
              <p className="text-lg font-medium">
                Total (excluding tax)
              </p>
              <p className="text-right mt-2 text-lg font-medium">
                ${restaurantSubcription?.upcomingInvoice?.totalExcludingTax}
              </p>
            </div>
          </section>
        </div>
      )}
      {/* Billing History */}

      <div className="bg-white rounded-3xl p-4 mt-8">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <section>
            <h3 className="text-xl ml-3 font-medium mb-4">Billing History</h3>
            <table className="w-full bg-white flex flex-col">
              <thead>
                <tr className="text-[#606360] flex justify-between w-full">
                  <th className="p-4 text-sm text-left w-[220px]">DATE</th>
                  <th className="p-4 text-sm text-left w-[220px]">DETAILS</th>
                  <th className="p-4 text-sm text-left w-[220px]">AMOUNT</th>
                  <th className="p-4 text-sm text-left w-[220px]">INVOICE/RECEIPT</th>
                </tr>
              </thead>
              <tbody>
                {restaurantSubcription?.invoices?.map(
                  (data: any, index: any) => (
                    <tr
                      key={index}
                      className="border-t border-[#EBF5FF] flex justify-between w-full"
                    >
                      <td className="p-4 text-sm w-[220px]">
                        {formatDate(data?.invoiceCreatedAtByStripe)}
                      </td>
                      <td className="p-4 text-sm w-[220px]">{data?.description}</td>
                      <td className="p-4  text-sm font-semibold w-[220px]">
                        ${data?.totalExcludingTax}
                      </td>
                      <td className="p-4 text-sm w-[220px]">
                        <button
                          className="px-4 py-2 bg-[#F5F5F6] text-blue-600 rounded-lg flex items-center gap-x-2"
                          onClick={() => openInvoice(data?.hostedInvoiceUrl)}
                        >
                          <img
                            className="w-[14px] h-[14px]"
                            src={DownloadIcon}
                            alt=""
                          />
                          Download
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        )}
      </div>
    </div>
  );
};

export default SubcriptionSetting;
