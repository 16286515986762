import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { BtnLoader } from "src/Loader";
import DropdownEB from "../Dropdown/DropdownEB";
import {
  add_table,
  edit_table,
} from "src/store/features/restuarant-table-slice";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { ADD_TABLE, UPDATE_TABLE } from "src/constants/mutations";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { successToast } from "src/utils/toasts";

const TABLE_SIZE = [
  { id: "1", name: "2" },
  { id: "2", name: "4" },
  { id: "3", name: "6" },
  { id: "4", name: "8" },
  { id: "5", name: "10" },
  { id: "6", name: "12" },
];

export const ManageTableModal = ({
  setShowCreateModal,
  title,
  editTableModal,
}: any) => {
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      tableNumber: "",
      // status: "1",
      tableSize: "",
    },
    validationSchema: Yup.object({
      tableNumber: Yup.string().required("Table Number is Required"),
      // status: Yup.string().required("Status is Required"),
      size: Yup.string().required("Size is Required"),
    }),
    onSubmit: () => {},
  });

  const manageTable = async () => {
    setLoading(true)
    try {
      let variables: any = {};
      if (!!editTableModal) {
        variables = {
          updateStoreTableInput: {
            storeCorrelationId: hasRestaurant.correlationId,
            storeTableCorrelationId: editTableModal?.correlationId,
            tableNumber: formik.values.tableNumber,
            tableSize: Number(formik.values.tableSize),
          },
        };
        delete variables?.updateStoreTableInput?.correlationId;
      } else {
        variables = {
          createStoreTableInput: {
            tableNumber: formik.values.tableNumber,
            tableSize: Number(formik.values.tableSize),
            storeCorrelationId: hasRestaurant.correlationId,
          },
        };
      }
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: !!editTableModal ? UPDATE_TABLE : ADD_TABLE,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors;
      }
      if (!!editTableModal) {
        dispatch(edit_table(data?.data?.updateStoreTable));
      } else {
        dispatch(add_table(data?.data?.createStoreTable));
      }
      setLoading(false)
      setShowCreateModal();
    } catch (error) {
      console.error("Error creating user:", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (!!editTableModal) {
      formik.setValues({
        ...editTableModal,
      });
    }
  }, []);

  const isDisabled = !formik.isValid || !formik.dirty;

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div
                className={`bg-white px-8 py-6 rounded-lg w-[400px] max-w-[400px] min-w-10 flex flex-col`}
              >
                <div className="top flex justify-between items-center">
                  <span className="text-xl font-semibold text-[#252525]">
                    {title}
                  </span>
                  <img
                    className="w-3 h-3 cursor-pointer"
                    src={closeIcon}
                    alt=""
                    onClick={setShowCreateModal}
                  />
                </div>
                <div className="mt-8 ">
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">Table Number</span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="number"
                      name="tableNumber"
                      id="tableNumber"
                      value={formik.values.tableNumber}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {/* <div className="w-full">
                    {!!editTableModal && (
                      <DropdownEB
                        title={"Select Status"}
                        data={[
                          { id: "1", name: "Available" },
                          { id: "2", name: "Reserved" },
                        ]}
                        id="status"
                        onSelect={(id) => formik.setFieldValue("status", id)}
                        // selectedId={formik.values.status}
                      />
                    )}
                  </div> */}
                  <div className="w-full">
                    <DropdownEB
                      title={"Select Size"}
                      data={TABLE_SIZE}
                      id="tableSize"
                      onSelect={(id) =>
                        formik.setFieldValue(
                          "tableSize",
                          TABLE_SIZE?.find((table) => table?.id == id)?.name
                        )
                      }
                      selectedId={
                        TABLE_SIZE?.find(
                          (table) => table?.name == formik.values.tableSize
                        )?.id
                      }
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-4 flex-1 items-end">
                  {loading ? (
                    <BtnLoader />
                  ) : (
                    <button
                      className={`px-8 text-xs mt-6 flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500 ${
                        !isDisabled && "opacity-20"
                      }`}
                      onClick={() => manageTable()}
                      // disabled={isDisabled}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
