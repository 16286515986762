import React, { useEffect, useRef, useState } from "react";
import SearchForm from "src/components/Searchform/SearchFrom";
import PlusIcon from "../../../assests/images/dashboard/Vector 2.png";
import SortIcon from "../../../assests/images/dashboard/swap.png";
import { ManageitemModal } from "src/components/AddItemsModal/ManageitemModal";
import SortbyDropdown from "src/pages/Menu/SortbyDropdown";
import { useLocation } from "react-router-dom";
import { GET_ITEMS } from "src/constants/queries";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import ItemsCard from "../ItemsCard";
import { BtnLoader } from "src/commonLoader/index";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_category_item_modal,
  search_category_items,
  set_category_items,
  sort_category_items,
} from "src/store/features/category-item-slice";

const MenuItem = () => {
  const [editCategoryItemModal, setEditCategoryItemModal] = useState<any>(null);
  const { filteredCategoryItems } = useSelector(
    (state: any) => state.categoryItem
  );
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const categoryId =
    location.pathname?.split("/")[location.pathname.split("/").length - 1];
  const categoryName = queryParameters.get("name");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const dropdownRef = useRef<any>(null);
  const { axBe } = useAxiosInterceptor();
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    getItems();
  }, [axBe, categoryId]);

  const getItems = async () => {
    setIsItemsLoading(true);
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_ITEMS,
        variables: {
          categoryCorrelationId: categoryId,
        },
      });
      dispatch(
        set_category_items({
          categoryItems: data.data?.itemsByCategoryCorrelationId || [],
        })
      );
      setIsItemsLoading(false);
    } catch (error) {
      console.error(error);
      setIsItemsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const searchCategoryItems = (value: string) => {
    dispatch(search_category_items(value));
  };

  const handleCategoryItemClick = (itemId: any) => {
    dispatch(delete_category_item_modal(itemId));
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                placeholder="Search Category..."
                onChange={(e) => searchCategoryItems(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="relative inline-block text-left" ref={dropdownRef}>
          <div className="flex justify-center my-4">
            <button
              className={`flex items-center justify-evenly duration-500 w-[118px] h-[46px] rounded-xl border-solid border border-[#FD7657] 
        ${
          isOpen
            ? "bg-[#FD76571A] text-[#FD7657]"
            : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
        }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown sortBy={sort_category_items} />}
        </div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-[90px]">
          <div
            className="h-[766px] bg-white rounded-xl flex justify-center items-center cursor-pointer"
            onClick={() => setShowCreateModal(!showCreateModal)}
          >
            <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
          </div>
        </div>

        <div className="w-full">
          {isItemsLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <BtnLoader />
            </div>
          ) : filteredCategoryItems?.length > 0 ? (
            <div className="flex flex-wrap">
              {filteredCategoryItems.map((item: any, index: number) => (
                <ItemsCard
                  key={index}
                  item={item}
                  handleCategoryItemClick={handleCategoryItemClick}
                  setEditCategoryItemModal={setEditCategoryItemModal}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p>Click the button on the left to create some items!</p>
            </div>
          )}
        </div>
      </div>

      {showCreateModal || !!editCategoryItemModal ? (
        <ManageitemModal
          categoryId={categoryId}
          categoryName={categoryName}
          title={!!editCategoryItemModal ? "Edit Item" : "Add Item"}
          setShowCreateModal={(isLoad: boolean) => {
            isLoad && getItems();
            !!editCategoryItemModal
              ? setEditCategoryItemModal(null)
              : setShowCreateModal(!showCreateModal);
          }}
          editCategoryItemModal={editCategoryItemModal}
          setEditCategoryItemModal={setEditCategoryItemModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default MenuItem;
