import {configureStore} from '@reduxjs/toolkit';
import authSlice  from './features/auth-slice';
import restaurantSlice from './features/restuarant-slice';
import employeeSlice from './features/employee-slice';
import categorySlice from './features/category-slice';
import comboSlice from './features/combo-slice';
import restaurantTableSlice from './features/restuarant-table-slice';
import categoryItemSlice from './features/category-item-slice';

const store = configureStore({
  reducer:{
    auth: authSlice,
    restaurant: restaurantSlice,
    employee: employeeSlice,
    category: categorySlice,
    restaurantTable: restaurantTableSlice,
    categoryItem: categoryItemSlice,
    combo: comboSlice,
  }
})

export default store;