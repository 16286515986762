import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import defaultImg from "../../assests/images/dashboard/default-thumbnail (1).jpg";
import editIcon from "../../assests/images/dashboard/pencil.png";
import infoIcon from "../../assests/images/dashboard/info.svg";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GRAPHQL_ENDPOINT, S3_BASE_URL } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { GET_COMBOS } from "src/constants/queries";
import { useDispatch } from "react-redux";
import { BtnLoader } from "src/Loader";
import ComboItemsModal from "../ComboItemsModal/ComboItemsModal";
import { set_combo, set_combo_loding } from "src/store/features/combo-slice";
import { successToast } from "src/utils/toasts";
import {
  ADD_COMBO,
  UPDATE_COMBO,
  UPLOAD_COMBO_IMAGE,
} from "src/constants/mutations";
import { useAuth } from "src/contexts/auth-provider";
import { mimeTypeToExtension } from "../RestaurantSetting/RestaurantSettingComponent";
import axios from "axios";
import Dropzone from "react-dropzone";

export const ManageComboModal = ({
  setShowCreateModal,
  title,
  editComboModal,
}: any) => {
  const { user, hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [isOtherLanguageEnabled, setIsOtherLanguageEnabled] = useState(false);
  const dispatch = useDispatch();
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [uploadImage, setUploadImage] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      englishName: "",
      nonEnglishName: "",
      // deadlineToPlaceOrder: "",
      // comboReadyTime: 0,
      // comboRepeatFrequenc: 0,
      // englishDescription: "",
      // nonEnglishDescription: "",
      price: "",
      itemCorrelationIds: []
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("Name is Required"),
      price: Yup.string().required("Price frequenc Time is Required"),
    }),
    onSubmit: () => {},
  });
  const { setValues } = formik;

  const handleToggleChange = () => {
    setIsOtherLanguageEnabled((prevState) => !prevState);
  };


  const createCombo = async () => {
    try {
      let variables: any = {};
      const comboCorrelationId = formik.values.itemCorrelationIds.map((item: any) => {
        return item.correlationId;
      });
      variables = {
        createCombosInput: {
          storeUserCorrelationId: user?.storeUser?.correlationId,
          storeCorrelationId: hasRestaurant.correlationId,
          createComboInputs:{
            storeCorrelationId: hasRestaurant.correlationId,
            ...formik.values,
          }
        },
      };
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: ADD_COMBO,
        variables: variables,
      });
      if (data?.errors?.length) {
        throw data?.errors;
      }
      if (
        (data?.data?.addCombo?.correlationId && uploadImage?.file) ||
        (editComboModal?.correlationId && uploadImage?.file)
      ) {
        await uploadComboImage(
          data?.data?.addCombo?.correlationId || editComboModal?.correlationId
        );
      } else if (data?.data?.addCombo?.correlationId && !uploadImage?.file) {
        getCombos();
        successToast("Combo Created!");
        setShowCreateModal();
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  // const manageCombo = async () => {
  //   try {
  //     let variables: any = {};
  //     const comboCorrelationId = formik.values.itemCorrelationIds.map((item: any) => {
  //       return item.correlationId;
  //     });
      
  //     console.log("comboCorrelationId---->",  comboCorrelationId);
      
  //     if (!!editComboModal) {
  //       variables = {
  //         updateComboInput: {
  //           ...formik.values,
  //           itemCorrelationIds: comboCorrelationId,
  //         },
  //       };
  //       delete variables?.updateComboInput?.bucketKeyName;
  //       delete variables?.updateComboInput?.correlationId;
  //     } else {
  //       variables = {
  //         createCombosInput: {
  //           ...formik.values,
  //           storeUserCorrelationId: user?.storeUser?.correlationId,
  //           storeCorrelationId: hasRestaurant.correlationId,
  //           itemCorrelationIds: comboCorrelationId,
  //         },
  //       };
  //     }
  //     const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
  //       query: !!editComboModal ? UPDATE_COMBO : ADD_COMBO,
  //       variables: variables,
  //     });
  //     if (data?.errors?.length) {
  //       throw data?.errors;
  //     }
  //     if (
  //       (data?.data?.addCombo?.correlationId && uploadImage?.file) ||
  //       (editComboModal?.correlationId && uploadImage?.file)
  //     ) {
  //       await uploadComboImage(
  //         data?.data?.addCombo?.correlationId || editComboModal?.correlationId
  //       );
  //     } else if (editComboModal?.correlationId && !uploadImage?.file) {
  //       getCombos();
  //       successToast("Combo Edited!");
  //       setShowCreateModal();
  //     } else if (data?.data?.addCombo?.correlationId && !uploadImage?.file) {
  //       getCombos();
  //       successToast("Combo Created!");
  //       setShowCreateModal();
  //     }
  //   } catch (error) {
  //     console.error("Error creating user:", error);
  //   }
  // };

  const uploadComboImage = async (comboCorrelationId: string) => {
    setUploadImageLoader(true);
    const variables = {
      createComboPreSignedUrlInput: {
        storeUserCorrelationId: user?.storeUser?.correlationId,
        storeCorrelationId: hasRestaurant.correlationId,
        comboName: formik.values.englishName,
        fileNameExtension: mimeTypeToExtension[uploadImage?.file?.type],
        comboCorrelationId,
      },
    };
    try {
      const res = await axBe.post(GRAPHQL_ENDPOINT, {
        query: UPLOAD_COMBO_IMAGE,
        variables: variables,
      });
      if (!res?.data?.error?.length) {
        const awsUrl =
          res.data?.data?.createUploadPreSignedUrlForCategory?.preSignedUrl;
        await axios.put(awsUrl, uploadImage.file, {
          headers: {
            "Content-Type": uploadImage?.file?.type,
            "x-amz-acl": "public-read",
          },
        });
        getCombos();
        setUploadImageLoader(false);
        setShowCreateModal();
        successToast(!!editComboModal ? "Combo Edited!" : "Combo Added!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setUploadImageLoader(false);
    }
  };

  const getCombos = async () => {
    dispatch(set_combo_loding(true));
    const variables = {
      retrieveCombosInput: {
        comboCorrelationIds: [],
      },
    };
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_COMBOS,
        variables: variables,
      });
      console.log("data--->", data);
      dispatch(
        set_combo({
          combo: data?.data?.combos || [],
        })
      );
      setTimeout(() => {
        // setIsComboLoader(false);
        dispatch(set_combo_loding(false));
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (file: any) => {
    setUploadImage({
      url: URL.createObjectURL(file),
      file,
    });
  };

  useEffect(() => {
    if (!!editComboModal) {
      setValues(editComboModal);
      if (editComboModal?.bucketName) {
        setUploadImage({
          url: S3_BASE_URL + editComboModal?.bucketName,
          file: null,
        });
      }
      setIsOtherLanguageEnabled(!!editComboModal?.nonEnglishName);
    }
  }, [editComboModal, setValues]);

  const addItems = () => {
    setShowItemModal(!showItemModal);
  };

  const isDisabled = !formik.isValid || !formik.dirty;
  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
            <div className="flex items-center justify-center bg-gray-100">
              <div
                className={`bg-white px-8 py-6 rounded-lg w-[788px] max-w-[790px] min-w-10 flex flex-col ${
                  isOtherLanguageEnabled ? "h-[410px]" : "h-[360px]"
                }`}
              >
                <div className="top flex justify-between items-center">
                  <span className="text-xl font-semibold text-[#252525]">
                    {title}
                  </span>
                  <img
                    className="w-3 h-3 cursor-pointer"
                    src={closeIcon}
                    alt=""
                    onClick={setShowCreateModal}
                  />
                </div>

                <div className="mt-8 flex gap-x-2">
                  <Dropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpg": [".jpg"],
                      "image/jpeg": [".jpeg"],
                    }}
                    onDrop={(acceptedFiles) =>
                      handleFileChange(acceptedFiles[0])
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <div className="w-[67px] h-[67px] rounded-lg border border-[#F1F1F2] flex justify-center items-center relative">
                          <img
                            src={
                              uploadImage?.url
                                ? uploadImage?.url
                                : editComboModal?.bucketKeyName
                                ? editComboModal?.bucketKeyName.includes("blob")
                                  ? editComboModal?.bucketKeyName
                                  : S3_BASE_URL + editComboModal?.bucketKeyName
                                : defaultImg
                            }
                            alt=""
                            className="w-[55px] h-[55px] object-cover"
                          />
                        </div>
                        <button>
                          <img
                            src={editIcon}
                            alt=""
                            className="w-6 h-6 absolute top-[85px] left-[83px]"
                            {...getRootProps()}
                          />
                          <input {...getInputProps()} />
                        </button>
                      </div>
                    )}
                  </Dropzone>
                  <div className="w-full ml-2">
                    <span className="text-[#9B9B9B] text-sm">
                    Combo  Name <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="englishName"
                      id="englishName"
                      value={formik.values.englishName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">
                      Price
                      <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="price"
                      id="price"
                      value={formik.values.price}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div className="mt-4 flex gap-x-2">
                  {/* <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">
                      Ready Time <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="comboReadyTime"
                      id="comboReadyTime"
                      value={formik.values.comboReadyTime}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                  {/* <div className="w-full">
                    <span className="text-[#9B9B9B] text-sm">
                      Price <span className="text-[red]">*</span>
                    </span>
                    <input
                      className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                      type="text"
                      name="price"
                      id="price"
                      value={formik.values.price}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div> */}
                </div>
                {/* <div className="w-full mt-4">
                  <span className="text-[#9B9B9B] text-sm">
                    Repeat frequenc <span className="text-[red]">*</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="comboRepeatFrequenc"
                    id="comboRepeatFrequenc"
                    value={formik.values.comboRepeatFrequenc}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div> */}
                {/* <div className="w-full mt-4">
                  <span className="text-[#9B9B9B] text-sm">Description</span>
                  <input
                    className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="englishDescription"
                    id="englishDescription"
                    value={formik.values.englishDescription}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div> */}
                <div className="divider border-[#EBF5FF] border-[1px] mt-2 mb-6"></div>

                <div className="flex mt-2 items-center">
                  <input
                    className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    role="switch"
                    checked={isOtherLanguageEnabled}
                    onChange={handleToggleChange}
                  />
                  <p className="mx-2 text-[14px] font-medium">Other language</p>
                  <img src={infoIcon} alt="" width={15} height={15} />
                </div>
                {isOtherLanguageEnabled && (
                  <>
                    <div className="w-full mt-4">
                      <span className="text-[#9B9B9B] text-sm">
                        Combo name (Other language)
                      </span>
                      <input
                        className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="text"
                        name="nonEnglishName"
                        id="nonEnglishName"
                        value={formik.values.nonEnglishName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                    {/* <div className="w-full mt-4">
                      <span className="text-[#9B9B9B] text-sm">
                        Combo Description (Other language)
                      </span>
                      <input
                        className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                        type="text"
                        name="nonEnglishDescription"
                        id="nonEnglishDescription"
                        value={formik.values.nonEnglishDescription}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div> */}
                  </>
                )}
                <div className="flex justify-end mt-4 flex-1 items-end gap-x-2">
                  {/* <button
                    className={`${
                      (!formik.isValid || !formik.dirty) && "opacity-[100%]"
                    } px-8 text-xs flex justify-center items-center gap-2 text-black h-[34px] rounded-md bg-[#F5F5F6] hover:bg-[#F5F5F6] hover:text-black duration-500`}
                    onClick={addItems}
                    // disabled={!formik.isValid || !formik.dirty}
                  >
                    Add items
                  </button> */}
                  {uploadImageLoader ? (
                    <BtnLoader />
                  ) : (
                    <button
                      className={`px-8 text-xs flex justify-center items-center gap-2 text-white h-[34px] rounded-md bg-orange hover:bg-[#F5F5F6] hover:text-black duration-500 ${
                        isDisabled && "opacity-20"
                      }`}
                      onClick={createCombo}
                      disabled={isDisabled}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showItemModal ? (
        <ComboItemsModal
          title="Select Menu Item for your combo"
          formik={formik}
          setShowItemModal={() => {
            setShowItemModal(!showItemModal);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
