import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  tables: [],
  filteredTables: [],
  hasTableId: null,
};

export const restaurantTableSlice = createSlice({
  name: "restaurantTable",
  initialState,
  reducers: {
    set_tables: (state :any, { payload }) => {
      state.tables = payload.tables;
      state.filteredTables = payload.tables;
    },
    add_table: (state, { payload }) => {
      state.tables = [...state.tables, payload];
      state.filteredTables = [...state.filteredTables, payload];
    },
    edit_table: (state, { payload }) => {
      const getIndex = state.tables.findIndex((table: any) => table.correlationId === payload?.correlationId);
      state.tables[getIndex] = payload;
      state.filteredTables[getIndex] = payload;
    },
    delete_table: (state, { payload }) => {
      const getIndex = state.tables.findIndex((table: any) => table.id === payload);
      state.tables.splice(getIndex, 1);
      state.filteredTables.splice(getIndex, 1);
    },
    search_table: (state, { payload }) => {
      state.filteredTables = state.tables.filter((table: any) => table.tableNumber?.toString().toLowerCase().includes(payload.toLowerCase()));
    },
    delete_table_modal: (state :any, { payload }) => {
      state.hasTableId = payload;
    },
    sort_tables: (state, { payload }) => {
      if (payload === "aToZ") {
        state.filteredTables = state.tables.slice().sort((a: any, b: any) => 
          a.tableNumber?.toString()?.toLowerCase().localeCompare(b.tableNumber?.toString().toLowerCase())
        );
      } else if (payload === "zToA") {
        state.filteredTables = state.tables.slice().sort((a:any, b: any) => 
          b.tableNumber?.toString().toLowerCase().localeCompare(a.tableNumber?.toString().toLowerCase())
        );
      }
    }, 
    set_table_loding:(state, { payload }) => {
      state.isTableLoading = payload;      
    },
  },
});

export const { set_tables, add_table, delete_table, edit_table, search_table, sort_tables ,delete_table_modal,set_table_loding} = restaurantTableSlice.actions;

export default restaurantTableSlice.reducer;
