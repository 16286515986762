import * as Yup from "yup";
import { useFormik } from "formik";

import { useState } from "react";

const ITEMS_SCHEMA = Yup.object().shape({
  item: Yup.object().shape({
    abbreviation: Yup.string(),
    englishName: Yup.string().required("English name is required"),
    nonEnglishName: Yup.string(),
    englishDescription: Yup.string(),
    price: Yup.number().required("Price is required"),
    nonEnglishDescription: Yup.string(),
    taxRate: Yup.number().required("Tax rate is required"),
    tag: Yup.string(),
    options: Yup.array()
      .of(
        Yup.object().shape({
          correlationId: Yup.string(),
          name: Yup.string().required("Name is required"),
          description: Yup.string(),
          renderSequence: Yup.number().required("Render sequence is required"),
          isRequired: Yup.boolean(),
          elements: Yup.array().of(
            Yup.object().shape({
              correlationId: Yup.string(),
              // name: Yup.string().required("Name is required"),
              description: Yup.string(),
              // renderSequence: Yup.number().required("Render sequence is required"),
              // price: Yup.number().required("Price is required"),
              isLoading: Yup.boolean(),
              isDeleting: Yup.boolean(),
            })
          ).test('is-required', 'At least one item is required', function (elements) {
            // If the options array is not provided, it defaults to undefined
            if (elements && elements.length > 0) {
              return elements.length > 0; // Require at least one option if the array is present
            }
            return true; // Allow empty options
          }),
        })
      )
      .test('is-required', 'At least one item is required', function (options) {
        // If the options array is not provided, it defaults to undefined
        if (options && options.length > 0) {
          return options.length > 0; // Require at least one option if the array is present
        }
        return true; // Allow empty options
      }),
  }),
});

export const useItemsForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      item: {
        abbreviation: "",
        englishName: "",
        nonEnglishName: "",
        englishDescription: "",
        nonEnglishDescription: "",
        price: "",
        taxRate: "",
        tag: "",
        options: [
          // {
          //   name: "",
          //   description: "",
          //   renderSequence: null,
          //   isRequired: false,
          //   elements: [
          //     {
          //       name: "",
          //       description: "",
          //       renderSequence: null,
          //       price: null,
          //     },
          //   ],
          // },
        ],
      },
    },

    validationSchema: ITEMS_SCHEMA,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setIsLoading(true);
        // Submit logic here
      } catch (error) {
        setStatus(false);
        setIsLoading(false);
        console.error("Submit error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return { formik, isLoading };
};
