import React, { forwardRef, useEffect, useRef, useState } from "react";
import PlusIcon from "../../assests/images/dashboard/Vector 2.png";
import SortIcon from "../../assests/images/dashboard/swap.png";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_category_modal,
  search_categories,
} from "src/store/features/category-slice";
import { BtnLoader } from "src/commonLoader/index";
import { ManageComboModal } from "src/components/ManageComboModal/ManageComboModal";
import ComboCard from "src/components/ComboCard/ComboCard";
import {
  delete_combo_modal,
  search_combo,
  set_combo,
  set_combo_loding,
} from "src/store/features/combo-slice";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { GET_COMBOS } from "src/constants/queries";
import infoIcon from "../../assests/images/dashboard/info.svg"
import * as Yup from "yup";
import { useFormik } from "formik";
import SortbyDropdown from "../Menu/SortbyDropdown";
import DatePicker from "react-datepicker";
import CelenderIcon from "../../assests/images/dashboard/Button container 2.png";


const Combo = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editComboModal, setEditComboModal] = useState<any>(null);
  const [isComboLoader, setIsComboLoader]: any = useState(false);
  const [isOtherLanguageEnabled, setIsOtherLanguageEnabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { axBe } = useAxiosInterceptor();
  const dropdownRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { filteredCombo } = useSelector((state: any) => state.combo);
  const { isComboLoading } = useSelector((state: any) => state.category);

  const formik = useFormik({
    initialValues: {
      englishName: "",
      nonEnglishName: "",
      deadlineToPlaceOrder: "",
      comboReadyTime: "",
      comboRepeatFrequenc: "",
      englishDescription: "",
      nonEnglishDescription: "",
      price: "",
      itemCorrelationIds: []
    },
    validationSchema: Yup.object({
      englishName: Yup.string().required("Name is Required"),
      deadlineToPlaceOrder: Yup.string().required("Deadline is Required"),
      comboReadyTime: Yup.string().required("Ready Time is Required"),
      comboRepeatFrequenc: Yup.string().required(
        "Repeat frequenc Time is Required"
      ),
      price: Yup.string().required("Price frequenc Time is Required"),
    }),
    onSubmit: () => {},
  });
  const { setValues } = formik;

  const handleComboClick = (comboId: any) => {
    dispatch(delete_combo_modal(comboId));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!isComboLoader) {
      setIsComboLoader(true);
      getCombos();
    }
  }, []);

  const getCombos = async () => {
    dispatch(set_combo_loding(true));
    const variables = {
      retrieveCombosInput: {
        comboCorrelationIds: [],
      },
    };
    try {
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: GET_COMBOS,
        variables: variables,
      });
      console.log("data--->", data);

      dispatch(
        set_combo({
          combo: data?.data?.combos || [],
        })
      );
      setTimeout(() => {
        setIsComboLoader(false);
        dispatch(set_combo_loding(false));
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  const searchCombo = (value: string) => {
    dispatch(search_combo(value));
  };

  const handleToggleChange = () => {
    setIsOtherLanguageEnabled((prevState) => !prevState);
  };

  const DateRreadyTime = forwardRef(
    ({ value, onClick, className }: any, ref: any) => (
      <>
      <div
        className="flex cursor-pointer relative items-center bg-white rounded-xl text-sm text-center"
        onClick={onClick}
      >
        <input className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2" ref={ref} placeholder={value} />
        <img
          className="w-[26px] h-[26px] absolute right-[10px]"
          src={CelenderIcon}
          alt=""
        />
      </div>
    </>
    )
  );
  const DatePlaceOrder = forwardRef(
    ({ value, onClick, className }: any, ref: any) => (
      <>
      <div
        className="flex cursor-pointer relative items-center bg-white rounded-xl text-sm text-center"
        onClick={onClick}
      >
        <input className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2" ref={ref} placeholder={value} />
        <img
          className="w-[26px] h-[26px] absolute right-[10px]"
          src={CelenderIcon}
          alt=""
        />
      </div>
    </>
    )
  );

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <form className="w-[280px]">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block border-[#F1F1F2] border w-full p-3 ps-10 text-sm text-black rounded-lg "
                placeholder="Search Category..."
                onChange={(e) => searchCombo(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="relative inline-block text-left" ref={dropdownRef}>
          <div className="flex justify-center my-4">
            <button
              className={`flex items-center justify-evenly duration-500 w-[118px] h-[46px] rounded-xl border-solid border border-[#FD7657] 
          ${
            isOpen
              ? "bg-[#FD76571A] text-[#FD7657]"
              : "bg-transparent text-[#FD7657] hover:bg-white hover:text-orange"
          }`}
              onClick={toggleDropdown}
            >
              <img
                className="w-[24px] h-[24px]"
                src={SortIcon}
                alt="Sort Icon"
              />
              <span>Sort by</span>
            </button>
          </div>
          {isOpen && <SortbyDropdown />}
        </div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-[90px]">
          <div
            className="h-[766px] bg-white rounded-xl flex justify-center items-center cursor-pointer"
            onClick={() => setShowCreateModal(!showCreateModal)}
          >
            <img className="w-[17px] h-[17px] " src={PlusIcon} alt="" />
          </div>
        </div>
        <div className="w-full">
          {isComboLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <BtnLoader />
            </div>
          ) : filteredCombo && filteredCombo.length > 0 ? (
            <div className="flex flex-wrap">
              {filteredCombo.map((combo: any, index: any) => (
                <ComboCard
                  key={index}
                  combo={combo}
                  handleComboClick={handleComboClick}
                  setEditComboModal={setEditComboModal}
                />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p>Click the button on the left to create some combo!</p>
            </div>
          )}
        </div>
      </div>
      {showCreateModal || !!editComboModal ? (
        <ManageComboModal
          title={!!editComboModal ? "Edit Combo" : "Create Combo"}
          setShowCreateModal={() =>
            !!editComboModal
              ? setEditComboModal(null)
              : setShowCreateModal(!showCreateModal)
          }
          editComboModal={editComboModal}
        />
      ) : (
        <></>
      )}
    </div>
    // <>
    // <div className="flex w-full justify-center">
    //     <div className={`bg-white rounded-lg w-[100%] relative mt-10 ${isOtherLanguageEnabled ? "h-[600px]" : "h-[420px]"}`}>
    //       <div className="pb-4 pt-8 w-[95%] m-auto">
    //         <div className="flex gap-x-2">
    //         <div className="w-full mt-2">
    //             <label className="block text-sm font-medium text-[#9B9B9B] p-1">
    //               Combo Name
    //             </label>
    //             <input
    //               name="englishName"
    //               type="text"
    //               className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //               placeholder="Enter Name"
    //               value={formik.values.englishName}
    //               onChange={formik.handleChange}
    //             />
    //           </div>
    //           <div className="w-full mt-2">
    //             <label className="block text-sm font-medium text-[#9B9B9B] p-1">
    //               Combo Price
    //             </label>
    //             <input
    //               name="price"
    //               type="number"
    //               className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //               placeholder="Enter price"
    //               value={formik.values.price}
    //               onChange={formik.handleChange}
    //             />
    //           </div> 
    //         </div>
    //         <div className="w-full mt-2">
    //             <label className="block text-sm font-medium text-[#9B9B9B] p-1">
    //               Combo Description
    //             </label>
    //             <input
    //               name="englishDescription"
    //               type="text"
    //               className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //               placeholder="Enter Description"
    //               value={formik.values.englishDescription}
    //               onChange={formik.handleChange}
    //             />
    //           </div>
    //           <div className="flex gap-x-2">
    //         <div className="w-full mt-2">
           
    //             <label className=" text-sm font-medium text-[#9B9B9B]">
    //               Combo Ready Time
    //             </label>
    //             <DatePicker
    //               selected={formik.values.comboReadyTime as any}
    //               onChange={(date) => formik.setFieldValue("comboReadyTime", date)}
    //               customInput={<DateRreadyTime />}
    //               minDate={new Date()}
    //             />
        
    //           </div>
    //           <div className="w-full mt-2">
    //           <label className=" text-sm font-medium text-[#9B9B9B]">
    //               Place Order Date
    //             </label>
    //             <DatePicker
    //               selected={formik.values.deadlineToPlaceOrder as any}
    //               onChange={(date) => formik.setFieldValue("deadlineToPlaceOrder", date)}
    //               customInput={<DatePlaceOrder />}
    //               minDate={new Date()}
    //             />
    //           </div> 
    //         </div>
    //           <div className="flex gap-x-2">
    //         <div className="w-full mt-2">
    //             <label className="block text-sm font-medium text-[#9B9B9B] p-1">
    //               Frequency
    //             </label>
    //             <input
    //               name="comboRepeatFrequenc"
    //               type="number"
    //               className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //               placeholder="Enter Frequence"
    //               value={formik.values.comboRepeatFrequenc}
    //               onChange={formik.handleChange}
    //             />
    //           </div>
    //           <div className="w-full mt-2">
    //             <label className="block text-sm font-medium text-[#9B9B9B] p-1">
    //               Menu Items
    //             </label>
    //             <input
    //               name="percent"
    //               type="number"
    //               className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //               placeholder="Enter percent"
    //               // value={formik.values.percent}
    //               // onChange={formik.handleChange}
    //             />
    //           </div> 
    //         </div>

    //         <div className="flex mt-4 items-center">
    //               <input
    //                 className="mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
    //                 type="checkbox"
    //                 role="switch"
    //                 checked={isOtherLanguageEnabled}
    //                 onChange={handleToggleChange}
    //               />
    //               <p className="mx-2 text-[14px] font-medium">Other language</p>
    //               <img src={infoIcon} alt="" width={15} height={15} />
    //             </div>
    //         {isOtherLanguageEnabled && (
    //               <>
    //                 <div className="w-full mt-4">
    //                   <span className="text-[#9B9B9B] text-sm">
    //                     Combo name (Other language)
    //                   </span>
    //                   <input
    //                     className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //                     type="text"
    //                     name="nonEnglishName"
    //                     id="nonEnglishName"
    //                     value={formik.values.nonEnglishName}
    //                     onBlur={formik.handleBlur}
    //                     onChange={formik.handleChange}
    //                   />
    //                 </div>
    //                 <div className="w-full mt-4">
    //                   <span className="text-[#9B9B9B] text-sm">
    //                     Combo Description (Other language)
    //                   </span>
    //                   <input
    //                     className="text-xs text-[#9B9B9B] h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
    //                     type="text"
    //                     name="nonEnglishDescription"
    //                     id="nonEnglishDescription"
    //                     value={formik.values.nonEnglishDescription}
    //                     onBlur={formik.handleBlur}
    //                     onChange={formik.handleChange}
    //                   />
    //                 </div>
    //               </>
    //             )}

    //       </div>
    //     </div>
    //   </div>
    
    // </>
  );
};

export default Combo;
