import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ErrorCard from "src/components/errorCard/ErrorCard";
import { API_ENDPOINT } from "src/constants/endpoints";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";

export default function Refresh() {
  const { connectedAccountId } = useParams();
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  const [error, setError] = useState(false);
  const { axBe } = useAxiosInterceptor();

  const tryLinkAccount = () => {
    if (connectedAccountId) {
      setAccountLinkCreatePending(true);
      axBe
        .post(
          `${API_ENDPOINT}stripe/connectedAccount/accountLink`,
          JSON.stringify({
            account: connectedAccountId,
          })
        )
        .then((response: any) => {
          setAccountLinkCreatePending(false);
          const { url, error } = response.data;
          if (url) {
            window.location.href = url;
          }
          if (error || response?.error) {
            throw error || response.error;
          }
        })
        .catch((error) => {
          console.error("error -> ", error);
          setError(true);
        });
    }
  };

  return (
    <ErrorCard
      heading={"Something went wrong while connecting."}
      subHeading={"Your Stripe account was not connected, Please try again"}
      onHanldeSecondaryBtn={tryLinkAccount}
      secondaryButtonText="Try again"
    />
  );
}
