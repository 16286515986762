export const PLANS = `query plans {
    plans{
      name,
      productDescription,
      monthlyPriceMonthlyBilled,
      monthlyPriceAnnuallyBilled,
      features
    }
  }`;

export const GET_ALL_RESTAURANTS = `query loggedInUserStores{
    loggedInUserStores{
      correlationId
      name
      bucketKeyName
      employeeCount
      address{
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }`;

export const RESTAURANT_SUBSCRIPTION_TAB_DATA = `
  query restaurantSubscriptionTabData($restaurantSubscriptionTabDataInput: RestaurantSubscriptionTabDataInput!) {
    restaurantSubscriptionTabData(restaurantSubscriptionTabDataInput: $restaurantSubscriptionTabDataInput) {
      customerPortalUrl
      eatsbitsSubscription{
      stripeSubscriptionId
      planId
      currentPeriodStart
      currentPeriodEnd
      status
    }
      invoices{
      stripeInvoiceNumber
      invoiceCreatedAtByStripe
      status
      periodStart
      periodEnd
      hostedInvoiceUrl
      nextPaymentAttempt
      totalExcludingTax
      description
    }
      subscribedPlan{
      planId
      tier
      frequency
      price
      productName
      productDescription
    }
      upcomingInvoice {
      stripeInvoiceNumber
      invoiceCreatedAtByStripe
      description
      status
      totalExcludingTax
      periodStart
      periodEnd
      nextPaymentAttempt
      hostedInvoiceUrl
    }
    }
  }
`;

export const STORES = `
  query stores($retrieveStoresInput: RetrieveStoresInput!) {
    stores(retrieveStoresInput: $retrieveStoresInput) {
      correlationId
      name
      nonEnglishName
      primaryContactPhoneNumber
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      style
      employeeCount
      connectedAccountId
      bucketKeyName
      categories {
        correlationId
        name
      }
      operationHours {
        day
        startHour
        endHour
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;

export const GET_CATEGORIES = `query categoriesByStoreCorrelationId($storeCorrelationId: UUID!){
    categoriesByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
    correlationId
    name
    nonEnglishName
    bucketKeyName
    items{
      correlationId
      abbreviation
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      taxRate
      tag
      bucketKeyName
    }
  }
}`;

export const GET_ITEMS = `query itemsByCategoryCorrelationId ($categoryCorrelationId: UUID!){
  itemsByCategoryCorrelationId(categoryCorrelationId: $categoryCorrelationId){
    correlationId
    englishName
    nonEnglishName
    englishDescription
    nonEnglishDescription
    price
    bucketKeyName
    abbreviation,
    taxRate,
    tag,
    itemOptions {
      correlationId
      name
      description
      renderSequence
      isRequired
      itemOptionElements {
        correlationId
        name
        description
        renderSequence
        price
      }
    }
  }
}`;

export const GET_TABLES = `query storeTablesByStoreCorrelationId ($storeCorrelationId: UUID!){
  storeTablesByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
   	correlationId
    storeCorrelationId
    tableNumber
    tableSize
    status
    currentOrderCorrelationId
  }
}`;

export const GET_COMBOS = `query combos ($retrieveCombosInput: RetrieveCombosInput!){
  combos(retrieveCombosInput: $retrieveCombosInput){
   correlationId
    englishName
    nonEnglishName
    englishDescription
    nonEnglishDescription
    deadlineToPlaceOrder
    comboReadyTime
    comboRepeatFrequency
    price
    itemList {
      correlationId
      abbreviation
      englishName
      nonEnglishName
      englishDescription
      nonEnglishDescription
      price
      taxRate
      tag
      bucketKeyName
      itemOptions {
      	correlationId
        name
        description
        renderSequence
        isRequired
         itemOptionElements {
          correlationId
          name
          description
          renderSequence
          price
        }
      }
    }
  }
}`;

export const GET_PROMOTIONS = `query promotionsByStoreCorrelationId ($storeCorrelationId: UUID!){
  promotionsByStoreCorrelationId(storeCorrelationId: $storeCorrelationId){
    correlationId
    eatsbitsUserCorrelationId
    eatsbitsUserEmail
    expiresAt
    percentOff
    stripePromotionCode
    maxRedemptions
    timesRedeemed
  }
}`;
export const GET_PROMOTIONS_BY_DATES = `query storeCustomersByStoreCorrelationIdBetweenTwoInstants( $storeCorrelationId: UUID!, $startTimeStamp: Long, $endTimeStamp: Long) {
  storeCustomersByStoreCorrelationIdBetweenTwoInstants(storeCorrelationId: $storeCorrelationId,startTimeStamp: $startTimeStamp,endTimeStamp: $endTimeStamp) {
    customerCorrelationId
    customerName
    customerEmail
    visitTimes
    lastVisitTime
  }
}`;

export const GET_PROMOTIONS_BY_SEARCH = `query storeCustomersByStoreCorrelationIdAndNameOrEmail($storeCorrelationId: UUID!,$keyword: String!) {
  storeCustomersByStoreCorrelationIdAndNameOrEmail(storeCorrelationId: $storeCorrelationId,keyword: $keyword) {
    customerCorrelationId
    customerName
    customerEmail
    visitTimes
    lastVisitTime
  }
}
`;
