import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { SIGNIN_STORE_USER } from "src/constants/mutations";
import { useAuth } from "src/contexts/auth-provider";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { toggle_login_expiry_modal } from "src/store/features/auth-slice";
import { useDispatch } from "react-redux";
import { BtnLoader } from "../../Loader/index";

export const LoginExpireModal = () => {
  const {
    user,
    setUser,
    token,
    refreshToken,
    signOut,
    setRefreshtoken,
    setToken,
  } = useAuth();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { axBe } = useAxiosInterceptor();
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    const variables = {
      storeUserSignInInput: {
        email: user.storeUser.email,
        password,
      },
    };
    if (!GRAPHQL_ENDPOINT) {
      return;
    }
    try {
      setInProgress(true);
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: SIGNIN_STORE_USER,
        variables: variables,
      });
      if (data.errors) {
        // Extract the first error message if available
        const errorMessage = data.errors[0]?.message || "An error occurred";
        return;
      }
      const token = data?.data?.storeUserSignIn?.accessToken;
      const refreshToken = data?.data?.storeUserSignIn?.refreshToken;
      const user = data?.data?.storeUserSignIn;
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      setRefreshtoken(refreshToken);
      setToken(token);
      setUser(user);
      dispatch(toggle_login_expiry_modal(false));
      setInProgress(false);
    } catch (error) {
      setInProgress(false);
      console.error("Error creating user:", error);
    }
  };

  const navigateToLogin = () => {
    signOut();
    dispatch(toggle_login_expiry_modal(false));
    navigate("/login");
  };

  return (
    <>
      <div
        className="relative z-[999]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-[999] overflow-y-auto">
          <div className="flex backdrop-blur min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8">
              <div className="flex items-center justify-center bg-gray-100">
                <div className="bg-white p-8 rounded-lg shadow w-full max-w-md">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Sign in to your account again
                  </h2>
                  <p className="text-sm text-[#616161] mb-6">
                    You were automatically signed out because your session
                    expired. Please sign in again to continue using the Stripe
                    Dashboard.
                  </p>
                  <div className="mb-4">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-[#616161]"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      className="mt-1 block w-full px-3 py-2 border border-[#F1F1F2] rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                  {!inProgress ? (
                    <button
                      disabled={!password}
                      className={
                        !password
                          ? "opacity-20	pointer-events-none w-full hover:text-orange duration-500 hover:bg-white bg-orange text-white py-2 px-4 rounded-md"
                          : "w-full hover:text-orange duration-500 hover:bg-white bg-orange text-white py-2 px-4 rounded-md"
                      }
                      onClick={onSubmit}
                    >
                      Continue
                    </button>
                  ) : (
                    <BtnLoader />
                  )}
                  <div className="mt-4 text-center">
                    <a
                      className="text-sm text-orange hover:text-black duration-500 cursor-pointer"
                      onClick={navigateToLogin}
                    >
                      Return to login
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
