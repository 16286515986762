import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { GRAPHQL_ENDPOINT } from "../../../constants/endpoints";
import {
  CREATE_RESTAURANT,
  UPDATE_RESTAURANT,
} from "../../../constants/mutations";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth-provider";
import { useSelector, useDispatch } from "react-redux";
import { add_restaurant } from "../../../store/features/restuarant-slice";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { BtnLoader } from "../../../commonLoader/index";
import { convertDateToMilliseconds } from "src/utils/date";
import CreateRestuarantHeader from "./../../../components/CreateRestuarantHeader/CreateRestuarantHeader";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import * as Yup from "yup";
import moment from "moment";
import axios from "axios";

const GeneralInformatiom = () => {
  const location = useLocation();
  const [inProgress, setInProgress] = useState(false);
  const isComingBack = location.state?.isComingBack;
  const restaurant = useSelector((state: any) => state.restaurant);
  const dispatch = useDispatch();
  const { axBe } = useAxiosInterceptor();
  const { user } = useAuth();
  const navigate = useNavigate();
  const minuteStep = 10;
  const format = "hh:mm a";

  const formik = useFormik({
    initialValues: {
      restaurantName: "",
      restaurantNameOtherLanguage: "",
      street: "",
      stateProvince: "",
      city: "",
      zipCode: "",
      email: user.storeUser.email,
      firstName: "",
      lastName: "",
      contactNumber: "",
      style:"",
      time: [
        { day: "MONDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "TUESDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "WEDNESDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "THURSDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "FRIDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "SATURDAY", endHour: 0, startHour: 0, isSelected: false },
        { day: "SUNDAY", endHour: 0, startHour: 0, isSelected: false },
      ],
    },
    validationSchema: Yup.object({
      restaurantName: Yup.string().required("Restaurant is Required"),
      street: Yup.string().required("Street is Required"),
      stateProvince: Yup.string().required("State is Required"),
      city: Yup.string().required("City is Required"),
      zipCode: Yup.string().required("Zipcode is Required"),
      email: Yup.string()
        .email("Invalid Email address")
        .required("Email is Required"),
      firstName: Yup.string().required("First name is Required"),
      lastName: Yup.string().required("Last name is Required"),
      contactNumber: Yup.number().required("Contact number is Required"),
    }),
    onSubmit: () => {},
  });

  const handleCheckboxChange = (index: any) => {
    const newSelection = !formik.values.time[index].isSelected;
    formik.setFieldValue(`time[${index}].isSelected`, newSelection);
  };

  const createRestaurant = async () => {
    const updatedTime = formik.values?.time?.map((item) => {
      let startMilliseconds = 0;
      let endMilliseconds = 0;
      if (item.startHour || item.endHour) {
        startMilliseconds = convertDateToMilliseconds(item.startHour);
        endMilliseconds = convertDateToMilliseconds(item.endHour);
      }
      return {
        day: item.day,
        startHour: startMilliseconds,
        endHour: endMilliseconds,
      };
    });
    const payload: any = {
      name: formik.values.restaurantName,
      nonEnglishName: formik.values.restaurantNameOtherLanguage,
      primaryContactFirstName: formik.values.firstName,
      primaryContactLastName: formik.values.lastName,
      style: formik.values.style,
      primaryContactEmail: user.storeUser.email,
      primaryContactPhoneNumber: formik.values.contactNumber?.toString(),
      address: {
        addressLine1: formik.values.street,
        city: formik.values.city,
        state: formik.values.stateProvince,
        zipCode: formik.values.zipCode,
      },
      operationHours: updatedTime,
    };

    if (!isComingBack) {
      payload.storeUserCorrelationId = user.storeUser.correlationId;
    }

    const variables = {
      [isComingBack ? "updateStoreInput" : "createStoreInput"]: {
        ...payload,
        ...(isComingBack && {
          storeCorrelationId:
            restaurant?.restaurantInfo?.detailInfo?.correlationId,
        }),
      },
    };

    if (!GRAPHQL_ENDPOINT) {
      console.error("GraphQL endpoint is not defined.");
      return;
    }

    try {
      setInProgress(true);
      const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
        query: isComingBack ? UPDATE_RESTAURANT : CREATE_RESTAURANT,
        variables: variables,
      });

      if (data.errors) {
        const errorMessage = data.errors[0]?.message || "An error occurred";
        setInProgress(false);
        return;
      }
      dispatch(
        add_restaurant({
          ...payload,
          originalTimePickerData: formik.values?.time,
          correlationId:
            data?.data?.createStore?.correlationId ||
            data?.data?.updateStore?.correlationId,
        })
      );
      navigate("/plans");
      setInProgress(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
      } else {
        setInProgress(false);
      }
      console.error("Error creating user:", error);
    }
  };

  useEffect(() => {
    if (isComingBack) {
      const data = restaurant?.restaurantInfo?.detailInfo;
      formik.setFieldValue("restaurantName", data.name);
      formik.setFieldValue("restaurantNameOtherLanguage", data.nonEnglishName);
      formik.setFieldValue("street", data.address?.addressLine1);
      formik.setFieldValue("stateProvince", data.address?.state);
      formik.setFieldValue("city", data.address?.city);
      formik.setFieldValue("zipCode", data.address?.zipCode);
      formik.setFieldValue("email", user.storeUser.email);
      formik.setFieldValue("firstName", data.primaryContactFirstName);
      formik.setFieldValue("lastName", data.primaryContactLastName);
      formik.setFieldValue("time", data.originalTimePickerData);
      setTimeout(() => {
        formik.setFieldValue("contactNumber", data.primaryContactPhoneNumber);
      }, 500);
    }
  }, [isComingBack]);

  const isDisabled = !formik.isValid || !formik.dirty;

  return (
    <>
      <CreateRestuarantHeader heading={"Create Restaurant"} />
      <div className="m-0 p-0 flex justify-center">
        <div className="flex justify-center items-center mb-20 mt-4 bg-white shadow p-8 rounded-3xl">
          <form className="relative">
            <div className="">
              <h1 className="pb-6 font-semibold text-sm text-[#9B9B9B] ">
                Please add clear information and details about your restaurant
                and its location. <br /> Make sure all the data matches and is
                accurate.
              </h1>
            </div>

            <div className="mb-2">
              <span className="text-xs font-normal text-[#9B9B9B]">
                Restaurant Name <span className="text-[red]">*</span>{" "}
              </span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="text"
                name="restaurantName"
                id="restaurantName"
                placeholder=""
                value={formik.values.restaurantName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.restaurantName && formik.errors.restaurantName ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.restaurantName}
                </div>
              ) : null}
            </div>

            <div className="mb-2">
              <span className="text-xs text-[#9B9B9B] ">
                Restaurant Name (Other language)
              </span>
              <input
                className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                type="restaurantNameOtherLanguage"
                name="restaurantNameOtherLanguage"
                id="restaurantNameOtherLanguage"
                placeholder=""
                value={formik.values.restaurantNameOtherLanguage}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.restaurantNameOtherLanguage &&
              formik.errors.restaurantNameOtherLanguage ? (
                <div className="text-orange text-xs mt-2">
                  {formik.errors.restaurantNameOtherLanguage}
                </div>
              ) : null}
            </div>

            <div className="flex justify-between gap-4">
              <div className="w-full">
                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    Street
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="street"
                    id="street"
                    placeholder=""
                    value={formik.values.street}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.street && formik.errors.street ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.street}
                    </div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    State/Province
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="stateProvince"
                    id="stateProvince"
                    placeholder=""
                    value={formik.values.stateProvince}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.stateProvince &&
                  formik.errors.stateProvince ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.stateProvince}
                    </div>
                  ) : null}
                </div>
                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                  style
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="style"
                    id="style"
                    placeholder=""
                    value={formik.values.style}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.style &&
                  formik.errors.style ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.style}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="w-full">
                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    City
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="city"
                    id="city"
                    placeholder=""
                    value={formik.values.city}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.city}
                    </div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    Zip Code
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    placeholder=""
                    value={formik.values.zipCode}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.zipCode && formik.errors.zipCode ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.zipCode}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <h1 className="font-medium text-lg">Primary Contact Details</h1>
            </div>

            <div className="flex justify-between mt-2 gap-4">
              <div className="w-full">
                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    First name
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder=""
                    value={formik.values.firstName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    Contact number
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="number"
                    name="contactNumber"
                    id="contactNumber"
                    placeholder=""
                    value={formik.values.contactNumber}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.contactNumber &&
                  formik.errors.contactNumber ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.contactNumber}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="w-full">
                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    Last name
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder=""
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-orange text-xs mt-2">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>

                <div className="mb-2">
                  <span className="text-xs text-[#9B9B9B] ">
                    Contact email
                    <span className="text-[red]"> *</span>
                  </span>
                  <input
                    className="text-xs text-[#9B9B9B]  h-[40px] w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                    type="email"
                    name="email"
                    id="email"
                    placeholder=""
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    
                  />
                </div>
              </div>
            </div>

            <div>
              <h1 className="font-medium text-lg mt-2">Opening Hours</h1>
            </div>

            <div className="w-full flex justify-between">
              <div className="flex flex-col w-[100%] mt-3">
                {formik.values?.time?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center gap-20 my-2"
                  >
                    <div className="flex items-center gap-36">
                      <span
                        className={`w-[50px] text-sm ${
                          item.isSelected ? "text-black" : "text-[#9B9B9B] "
                        }`}
                      >
                        {item.day.charAt(0).toUpperCase() +
                          item.day.slice(1).toLowerCase()}
                      </span>
                      <div>
                        <input
                          className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[#9B9B9B] before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-orange checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          checked={item.isSelected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label
                          className={`inline-block pl-[0.15rem] text-sm hover:cursor-pointer ${
                            item.isSelected ? "text-black" : "text-[#9B9B9B] "
                          }`}
                        >
                          {item.isSelected ? "Open" : "Closed"}
                        </label>
                      </div>
                    </div>
                    <div className="w-[300px]">
                      {item.isSelected && (
                        <div className="flex gap-8">
                          <div>
                            <TimePicker
                              minuteStep={minuteStep}
                              showSecond={false}
                              defaultValue={moment(
                                item.startHour || "00:00",
                                format
                              )}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  `time[${index}].startHour`,
                                  value.toISOString()
                                );
                              }}
                              format={format}
                              allowEmpty={false}
                            />
                          </div>
                          <div>
                            <TimePicker
                              minuteStep={minuteStep}
                              showSecond={false}
                              defaultValue={moment(
                                item.endHour || "00:00",
                                format
                              )}
                              onChange={(value) => {
                                formik.setFieldValue(
                                  `time[${index}].endHour`,
                                  value.toISOString()
                                );
                              }}
                              format={format}
                              allowEmpty={false}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>

          <div className="flex items-center justify-center  gap-2 fixed bottom-0 p-4 bg-white w-full z-50">
            <div className="flex justify-center  mx-1 ">
              <button
                onClick={() => navigate("/dashboard")}
                className="hover:bg-orange hover:text-white duration-500 w-[117px] h-[40px]  rounded-xl bg-[#F5F5F6] text-xs"
              >
                Cancel
              </button>
            </div>

            <div className="flex justify-center  mx-1 ">
              {inProgress ? (
                <BtnLoader />
              ) : (
                <button
                  className={`hover:bg-white hover:text-orange duration-500 w-[117px] h-[40px] text-white rounded-xl bg-orange text-xs ${
                    isDisabled && "opacity-20"
                  }`}
                  onClick={createRestaurant}
                  disabled={isDisabled}
                  type="button"
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInformatiom;
