import closeIcon from "../../assests/images/dashboard/Vector 3.png";
import { TagsInput } from "react-tag-input-component";
import { forwardRef, useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { useAxiosInterceptor } from "src/hooks/useAxiosInterceptor";
import { useAuth } from "src/contexts/auth-provider";
import { GRAPHQL_ENDPOINT } from "src/constants/endpoints";
import { ADD_PROMOTION } from "src/constants/mutations";
import { successToast } from "src/utils/toasts";
import { checkEmailValid } from "src/utils/email";
import { BtnLoader } from "src/Loader";

const PromotionSchema = Yup.object().shape({
  userEmails: Yup.array()
    .of(Yup.string().email("Invalid email"))
    .required("Required"),
  expiry: Yup.string().required("Expiry date is required"),
  percent: Yup.number()
    .min(0, "Must be at least 0%")
    .max(100, "Must be 100% or less")
    .required("Percentage is required"),
  isExistingCustomer: Yup.boolean(),
  maxNumberOfRedemptions: Yup.string().required(
    "Max Number Of Redemptions date is required"
  ),
});

export const CreatePromotionModal = ({
  title,
  isShowEmailInput = true,
  selectedEmails,
  onClose,
}: any) => {
  const { hasRestaurant } = useAuth();
  const { axBe } = useAxiosInterceptor();
  const [selectedInputEmails, setSelectedEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      userEmails: [],
      expiry: "",
      percent: "",
      maxNumberOfRedemptions: "",
      isExistingCustomer: false,
    },
    validationSchema: PromotionSchema,
    onSubmit: () => {},
  });

  console.log("selectedEmails--->",selectedEmails);
  

  const DateCustomInput = forwardRef(
    ({ value, onClick, className }: any, ref: any) => (
      <input
        name="expiry"
        type="text"
        className={`h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2 ${className}`}
        placeholder="Enter expiry"
        onClick={onClick}
        ref={ref}
        value={value}
      />
    )
  );

  useEffect(() => {
    if (!isShowEmailInput) {
      formik.setFieldValue("userEmails", selectedEmails);
      formik.setFieldValue("isExistingCustomer", true);
    }
  }, [selectedEmails]);

  const submitPromotion = async () => {
    setLoading(true);
    const { userEmails, expiry, percent, isExistingCustomer } = formik.values;
    if (!formik.values?.userEmails?.length) {
      setLoading(false);
      return;
    }
    const variables: any = {
      createPromotionCodeInput: {
        userEmails: userEmails,
        promotionExpiry: Math.floor(new Date(expiry).getTime() / 1000),
        percentOff: percent,
        isExistingCustomer: isExistingCustomer,
        storeCorrelationId: hasRestaurant?.correlationId,
      },
    };
    const { data } = await axBe.post(GRAPHQL_ENDPOINT, {
      query: ADD_PROMOTION,
      variables: variables,
    });
    setLoading(false);
    if (!data?.data?.error?.length) {
      formik.setFieldValue("expiry", "");
      formik.setFieldValue("percent", "");
      if (isShowEmailInput) {
        formik.setFieldValue("userEmails", []);
      }
    }
    onClose();
    successToast("Promotion Created!");
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="promo-modal fixed inset-0 flex bg-black/30 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative w-[50%] h-[370px] transform overflow-hidden rounded-lg bg-white text-left shadow-[0px_4px_0px_880px_rgba(37,37,37,0.75)] transition-all sm:my-8">
          <div className="bg-white rounded-lg w-[100%] relative p-6">
            <button
              onClick={onClose}
              className="absolute h-[12px] w-[12px] top-6 right-6"
            >
              <img src={closeIcon} alt="Close" />
            </button>
            <h2 className="text-lg font-bold mb-4">{title}</h2>
            <div>
              {isShowEmailInput && (
                <div>
                  <label className="block text-sm text-[#9B9B9B] font-medium p-1">
                    Add Emails
                  </label>
                  <TagsInput
                    value={selectedInputEmails}
                    name="userEmails"
                    placeHolder="Enter emails"
                    onChange={(emails) => {
                      const validEmails: any = emails.filter((email: string) =>
                        checkEmailValid(email)
                      );
                      setSelectedEmails(validEmails);
                      formik.setFieldValue("userEmails", validEmails);
                    }}
                  />
                </div>
              )}
              <div className="mt-2 w-full">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Expiry
                </label>
                <DatePicker
                  className="promo-date-picker"
                  selected={formik.values.expiry as any}
                  onChange={(date) => formik.setFieldValue("expiry", date)}
                  customInput={<DateCustomInput />}
                  minDate={new Date()}
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Percent
                </label>
                <input
                  name="percent"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter percent"
                  value={formik.values.percent}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mt-2">
                <label className="block text-sm font-medium text-[#9B9B9B] p-1">
                  Max number of redemptions
                </label>
                <input
                  name="maxNumberOfRedemptions"
                  type="number"
                  className="h-[40px] text-xs w-full border rounded-lg border-[#F1F1F2] mt-1 px-2"
                  placeholder="Enter max number of redemptions"
                  value={formik.values.maxNumberOfRedemptions}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mt-6 flex justify-end space-x-2">
                {loading ? (
                  <BtnLoader />
                ) : (
                  <button
                    className={`${
                      !formik.isValid && "opacity-20"
                    } text-white bg-orange text-sm w-24 h-[35px] rounded-lg`}
                    onClick={submitPromotion}
                    disabled={!formik.isValid}
                  >
                    Create
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
